import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../ui/logo'
import AdminMenu from '../../../data/menus/admin-menu'
import UserMenu from '../../../data/menus/user-menu'
import { RiArrowDownSLine } from 'react-icons/ri'

import styles from './sidebar.module.scss'

const AppSidebar = ({ user, setSidebarOpen }) => {
  const location = useLocation()
  const [expandedMenu, setExpandedMenu] = React.useState(null)

  const getMenu = () => {
    if (user.is_admin) {
      return AdminMenu
    }

    return UserMenu
  }

  return (
    <div className={styles.sidebar}>
      <div className={styles.logo}>
        <Link to="/app">
          <Logo />
        </Link>
      </div>
      <div className={styles.menu}>
        <ul>
          {getMenu().map((item) => {
            return (
              <li
                key={`sidebarMenu_${item.key}`}
                data-expanded={expandedMenu === `sidebarMenu_${item.key}`}
                data-active={
                  location.pathname === '/app'
                    ? item.path === '/app'
                    : location.pathname.includes(item.path) &&
                      item.path !== '/app'
                }
              >
                <Link
                  to={item.path}
                  onClick={(e) => {
                    if (item.childs && item.childs.length > 0) {
                      e.preventDefault()
                      setExpandedMenu(
                        expandedMenu === `sidebarMenu_${item.key}`
                          ? null
                          : `sidebarMenu_${item.key}`,
                      )
                    } else {
                      setSidebarOpen(false)
                    }
                  }}
                >
                  <div className={styles.icon}>{item.icon}</div>
                  <div className={styles.name}>{item.name}</div>
                  {item.childs && item.childs.length > 0 ? (
                    <div className={styles.extraIcon}>
                      <RiArrowDownSLine />
                    </div>
                  ) : null}
                </Link>
                {item.childs && item.childs.length > 0 ? (
                  <ul>
                    {item.childs.map((child) => (
                      <li key={`${item.key}-${child.key}`}>
                        <Link
                          to={child.path}
                          onClick={() => setSidebarOpen(false)}
                        >
                          {child.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default AppSidebar
